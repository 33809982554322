import React, { Component } from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'

/* eslint-disable no-unused-vars */
import { landingPage } from '@/queries/'
/* eslint-enable */
import Feeds from '@/shared/Feeds'
import { setupPage } from '@/utils/common'

class AdvicePage extends Component {
	constructor(props) {
		super(props)

		setupPage(props)
	}

	render() {
		const {
			data: {
				craft: { entry }
			},
			location
		} = this.props

		return <Feeds entry={entry} location={location} />
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({ player: { setPageType, setAudioBarVisibility } }) => ({
	setPageType,
	setAudioBarVisibility
})

export default connect(
	mapState,
	mapDispatch
)(AdvicePage)

export const pageQuery = graphql`
	query AdviceQuery {
		craft {
			entry(id: 4) {
				...landingPage
			}
		}
	}
`
